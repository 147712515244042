<script setup>
definePageMeta({
  layout: 'moto-layout',
});
const backend = useBackend();
const route = useRoute();

const slug = ref('');
slug.value = route.path.split('/').pop();

const articleData = ref(null);
const categories = ref([]);
const worthToRead = ref([]);

const {data: articleResponse, pending: articlePending, error: articleError} = await useAsyncData(
  `article-${slug.value}`,
  () => {
    console.log('Pobieranie danych artykułu dla slug:', slug.value);
    return backend.getMotoArticleData(slug.value);
  },
  {
    watch: [slug],
  }
);

watch(
  () => articleResponse.value,
  (newVal) => {
    if (articleResponse.value.status === 500) {
      return navigateTo({name: 'moto'})
    }
    if (newVal && newVal.data) {
      articleData.value = newVal.data.article;
      categories.value = newVal.data.categories || [];
      worthToRead.value = newVal.data.worth_to_read || [];
    } else {
      articleData.value = null;
      categories.value = [];
      worthToRead.value = [];
    }
  },
  {immediate: true}
);

const loaded = computed(() => !articlePending.value && articleData.value);

useHead(() => {
  const defaultTitle = 'Moto Vehis - najlepszy portal motoryzacyjny w Polsce';
  const defaultDescription = 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź';

  const seoTitle = articleData.value?.title || defaultTitle;
  const seoDescription = articleData.value?.seo_description || defaultDescription;
  const canonicalUrl = `https://vehis.pl${route.fullPath}`;

  return {
    title: seoTitle,
    meta: [
      {name: 'description', content: articleData.value?.meta_description || seoDescription},
      {property: 'og:title', content: seoTitle},
      {property: 'og:description', content: articleData.value?.meta_description || seoDescription},
      {property: 'og:type', content: 'article'},
      {property: 'og:image', content: articleData.value?.main_photo || 'https://vehis.pl/moto/assets/img/moto-vehis-logo-new.png'},
    ],
    link: [
      {rel: 'canonical', href: canonicalUrl},
    ],
  };
});
</script>
<template>
  <Container no-paddings>
    <div v-if="loaded">
      <LayoutColumns>
        <LayoutMainColumn noflex>
          <BlogBreadCrumbs
            :breadcrumbs="[
              { label: 'Strona główna', to: '/moto' },
              { label: articleData.category.name || '', to: `/moto/kategoria/${articleData.category.slug}` },
              { label: articleData.title || '', to: null }
            ]"
          />
          <h1 class="pt-6 mb-6 text-3xl font-medium text-azure-dark">{{ articleData.title }}</h1>
          <div class="mb-12">
            <p class="text-sm font-extralight block mb-1">
              {{ articleData?.publication_date }}
            </p>
<!--            <p class="border-t border-solid border-black inline-block">-->
<!--              {{ articleData?.author }}-->
<!--              <span class="font-extralight">Autor</span>-->
<!--            </p>-->
          </div>
          <BlogPostImage :imageUrl="articleData?.main_photo" />
          <BlogArticleBody :content="articleData?.content" />
          <FormContact class="mt-12 mb-16" title="Szukasz samochodu? Doradca VEHIS ma dla Ciebie oferty ponad 8 000 dostępnych samochodów!" standalone />
        </LayoutMainColumn>
        <LayoutSideColumn class="hidden lg:block pb-4 !bg-transparent">
          <MainContentHeader>Warto przeczytać</MainContentHeader>
          <div class="flex gap-6 flex-col mb-6">
            <BlogAsideCard
              v-for="(item, index) in worthToRead"
              :key="index"
              :item="item"
              :position="'Warto przeczytać'"
            />
          </div>
          <BannerSidebar :bannerSlot="'moto-artykul-300x600'" class="mb-6" />
          <BlogCarOfferBanner v-if="articleData?.foreign_car" :foreignCar="articleData?.foreign_car" />
        </LayoutSideColumn>
      </LayoutColumns>
      <!-- Sekcja 'Wybrane dla Ciebie' -->
      <!-- <div v-if="worthToRead.length > 0" class="border-t border-t-celadon pb-16">
        <h3 class="pt-6 text-2xl font-normal text-celadon mb-6">Wybrane dla Ciebie</h3>
        <BlogSlider :articles="worthToRead" :no-aside-cards="true" :is-shadow-boxed="true" />
      </div> -->
    </div>
  </Container>
</template>
